import axios from "axios";
// import httpClient from "./axios.js"
const serverHost = process.env.REACT_APP_SERVER_HOST;

// set timeout to 3 seconds in case server is down
const httpClient = axios.create()
httpClient.defaults.timeout = 3000
httpClient.defaults.headers.common['Authorization'] = `JWT ${localStorage.getItem("token")}`
httpClient.defaults.headers.get['Content-Type'] = 'application/json'
httpClient.defaults.baseURL = serverHost + '/api/'

export { httpClient }

export const fetchProfile = async auth_token => {
  httpClient.defaults.headers.common['Authorization'] = `JWT ${auth_token}`
  const profileReq = await httpClient.get('/user/profile/')
  return profileReq
};

export const fetchEventInfo = () => {
  return httpClient.get('/user/event_token/')
};

export const getEventList = me => {
  if (me) {
    return httpClient.get('events/?me')
  }
  return httpClient.get('/events/')
};

export const getEventDetail = eventId => {
  return httpClient.get(`/events/${eventId}`)
};

export const eventSignup = eventId => {
  return httpClient.post(`/events/${eventId}/participants/`)
}

export const eventCancelSignup = eventId => {
  return httpClient.delete(`/events/${eventId}/participants/`)
}

export const eventCheckIn = eventId => {
  return httpClient.patch(`/events/${eventId}/participants/`)
}

// export const updateParticipantStatus = (eventId, status) => {
//   return httpClient.post('/event/update_participant_status/',
//     {
//       event_id: eventId,
//       status: status,
//     })
// };

export const submitDateRating = (ratingInfo, speedDateID) => {
  return httpClient
      .post('/date/date_rating/',
      {
        ratingInfo: ratingInfo,
        speedDateID: speedDateID
      })
  }

// export const fetchDateProfile_OLD = dateOrder => {
//   return httpClient.get(`/date/get_other_date_profile/${dateOrder}`);
  
// };

export const fetchNextDateInfo = (eventId, dateOrder) => {
  return httpClient.get(`/events/${eventId}/get_other_date_profile/${dateOrder}`)
}

export const getServerTime = () => {
  return httpClient.get('/date/get_server_time');
};

export const listMatches = eventId => {
  return httpClient.get(`/events/${eventId}/list_matches`);
};

/* ADMIN API CALLS */

export const adminGetEventList = () => {
  return httpClient.get('/admin/events/')
}

export const adminGetEventDetail = eventId => {
  return httpClient.get(`/admin/events/${eventId}`)
}

export const adminGetEventParticipants = eventId => {
  return httpClient.get(`/admin/events/${eventId}/participants/`)
}

export const adminDeleteEventParticipant = (eventId, userId) => {
  return httpClient.delete(`/admin/events/${eventId}/participants/${userId}`)
}

export const adminUpdateParticipantStatus = (eventId, userId, status) => {
  return httpClient.put(`/admin/events/${eventId}/participants/${userId}`,
      {
        status: status,
      }
    )
}

export const adminAddEventParticipant = (eventId, user) => {
  return httpClient.post(`/admin/events/${eventId}/participants/`,
  {
    user: user,
  })
}

export const adminGetUserList = eventId => {
  return httpClient.get('/admin/users/', {
    params: {
      exclude_event: eventId
    }
  })
}

export const adminRunMatching = (eventId, numDates, numTables, tableNumStart)  => {
  return httpClient
    .post(`/admin/events/${eventId}/run_matching`,
    {
      event_id: eventId,
      num_tables: numTables,
      num_dates: numDates,
      table_num_start: tableNumStart
    })
}
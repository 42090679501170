import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarText,
  Container,
  Row,
  Col,
  Jumbotron,
  Button
} from "reactstrap";
import { useHistory, NavLink as RRNavLink, Link } from "react-router-dom"
import { Nav as NavBstrap } from "reactstrap";
function Nav(props) {
  const logged_in = localStorage.getItem("token") ? true : false
  let history = useHistory()


  const logged_out_nav = (
    <React.Fragment>
      <NavItem>
        <RRNavLink className="nav-link-custom nav-link" to="/login">
          Log in
        </RRNavLink>
      </NavItem>
      <NavItem>
        <RRNavLink className="nav-link-custom nav-link" to="/signup">
          Sign up
        </RRNavLink>
      </NavItem>
    </React.Fragment>
  );

  const logged_in_nav = (
    <React.Fragment>
      <NavItem>
        <RRNavLink className="nav-link-custom nav-link" to="/profile">
          Profile
        </RRNavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link-custom nav-link" href="#" onClick={props.handle_logout}>
          Log Out
        </NavLink>

      </NavItem>
    </React.Fragment>
  );

  return (
    <div>
      <Navbar expand="md" className="navbar">
          <NavbarBrand href="/">Click for home</NavbarBrand>
          <NavBstrap className="ml-auto" navbar>
            {logged_in ? logged_in_nav : logged_out_nav}
          </NavBstrap>
      </Navbar>
      {props.children}
    </div>
  );
}

export default Nav;

Nav.propTypes = {
  display_form: PropTypes.func.isRequired,
  handle_logout: PropTypes.func.isRequired
};

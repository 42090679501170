import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";

import "./App.css";

const serverHost = process.env.REACT_APP_SERVER_HOST;

const SpeedDate = ({
  timeOffset,
  currentDateInfo,
  handleEndDate
  }) => {

const otherDateProfile = currentDateInfo.other_date_profile
  

    return (
    <React.Fragment>
    <div className="container-fluid mt-2">
      <Row>
        <div className="col-sm-12 col-md-6 mx-auto p-1">
          <div
            style={{
              backgroundColor: "#dee2e6",
              width: "100%",
              // boxShadow: "6px 10px 10px -7px rgba(0,0,0,0.75)",
              // WebkitBoxShadow: "6px 10px 10px -7px rgba(0,0,0,0.75)",
              // MozBoxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)"
            }}
            className="border sidebar rounded mx-auto mb-3 p-1"
          >
            <h5
            className="text-center"
            // style={{
            //   color: "black",
            //   }}
              >
              Your current speed date!
            </h5>
          </div>
        </div>
      </Row>

      
      <div className="row">
        <div className="col-sm-12 col-md-6 mx-auto mb-3">
        <img src={serverHost+otherDateProfile.profile_picture}
            className="img-fluid mx-auto float-left d-block"
            style={{
              // maxWidth: "100%",
              maxHeight: "20vh",
              // height: "50px",
              objectFit: "cover",
              height: "auto"
            }}
          />
            <h3 className="text-center">{otherDateProfile.first_name}, {otherDateProfile.age}</h3>
        </div>
      </div>
      <div className="row">
      {/* <div className="col-sm-12 col-md-6 mx-auto mb-3"> */}
      {/* <div className="mx-auto mb-3"
      style={{width: "50%", height: "50%"}}> */}
        {/* <div style={{maxHeight: "500px"}}>
            <img src={serverHost+otherDateProfile.profile_picture}
            className="img-fluid mx-auto text-center d-block"
            style={{
              // maxWidth: "100%",
              maxHeight: "25vh",
              // height: "50px",
              objectFit: "cover",
              height: "auto"
            }}
          />
            </div> */}
        {/* </div> */}

        <div className="col-sm-12 col-md-12 col-lg-12">

          <div
            className="other-date-profile border rounded sidebar mx-auto"
            style={{ backgroundColor: "rgb(230, 234, 234)" }}
          >
            <div className="sidebar-header border-bottom">
              <h5 className="p-2 pl-3">
                About {otherDateProfile.first_name}
              </h5>
            </div>

             <div className="sidebar-content p-3">
              <div className="info_segment">
                <span className="info_field">Where I'm from</span>
                <span className="info_response">
                  {otherDateProfile.where_from}
                </span>
              </div>
              <div className="info_segment">
                <span className="info_field">What I do for a living</span>
                <span className="info_response">
                  {otherDateProfile.occupation}
                </span>
              </div>
              <div className="info_segment">
                <span className="info_field">
                  Three things I do for fun
                </span>
                <span className="info_response">
                  {otherDateProfile.interests}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="mx-auto text-center mt-2">
        <button
        className="btn btn-primary"
        onClick={e => handleEndDate()}
        >
          Click here when the date is over!
        </button>
        </div>
      </div>

      
      

    </div>
  </React.Fragment>
    )

  }

  export { SpeedDate };
import React, { useState, useEffect } from "react";
import { Alert, Fade } from "reactstrap";

const InfoMessage = ({
  toggle,
  content,
  style = {},
  messageType = "success"
}) => {
  const [visible, setVisible] = useState(true);
  const [fadeIn, setFadeIn] = useState(true);

  const onDismiss = () => {
    setVisible(false);
  };

  return (
    <Alert
      style={style}
      color={messageType}
      isOpen={visible}
      toggle={toggle ? onDismiss : undefined}
    >
      <Fade in={fadeIn} timeout={150}>
        {content()}
      </Fade>
    </Alert>
  );
};

export default InfoMessage;

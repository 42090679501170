import React, { useEffect, useState}  from "react";
import {
    Alert
  } from "reactstrap";
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    useHistory,
    useParams,
    Redirect
} from "react-router-dom";
import { PrivateRoute } from "../Routes.js"
import { getEventDetail, eventSignup, eventCancelSignup, eventCheckIn } from "../api"
import EventsList from "./EventsList";
import EventDetail from "./EventDetail";
import BeginCheckIn from "../BeginCheckIn";
import CompletedCheckIn from "../CompletedCheckIn";
import ShowDateMatches from "../ShowDateMatches";

import DatingMain from "../Dating/DatingMain"


const EventsRouter = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    // we use useLocation() as a dependency to the useEffect
    // so the events list updates if the user signs up in eventDetial and clicks the Back button
    // const location = useLocation()
    
    const loggedIn = localStorage.getItem("token") ? true : false
    return (
    <div className="container mt-3">
        <Switch>
            <Route path={path} exact>
                <EventsList 
                />
            </Route>
            <Route path={`${path}/:eventId/dating/start_over`}
            render={props => {
                    localStorage.removeItem(`event-${props.match.params.eventId}-roundNumber`)
                    history.push(`/events/${props.match.params.eventId}/dating`)
                }} />
            <Route path={`${path}/:eventId/dating`}
            >
                <DatingMain />
            </Route>
            <PrivateRoute
                path={`${path}/:eventId/matches`}
                loggedIn={loggedIn}>
                    <ShowDateMatches />
            </PrivateRoute>

            <PrivateRoute
                path={`${path}/:eventId`}
                loggedIn={loggedIn}>
                    <EventDetailRouter />
            </PrivateRoute>
            {/* <Route
                path={`${path}/:eventId`}
                render={({ match, history }) => {
                    const event = events.find(({id}) => id == match.params.eventId )
                    // const signedUp = myEvents.
                    if (!event) { 
                        
                        setTimeout(() => { 
                            history.push('/events');
                          }, 5000)
                          return ( <>Event not found. Sending you back to the main events screen in 5 seconds.</>)
                        }
                    return ( 
                        <>
                        <button
                        className="btn btn-link mb-2"
                        // onClick={() => history.goBack()}>
                        onClick={() => history.push('/events')}>
                            &lt;&lt; Back
                        </button>
                        <EventDetail
                            event={event}
                            handleEventSignup={handleEventSignup} 
                        />
                        </>
                    )
                }} 
                exact
            /> */}

            <Route>
                404 - Not found.
            </Route>
            {/* <Redirect  */}
        </Switch>
    </div>
    )
}


/// EVENT DETAIL ROUTER
const EventDetailRouter = () => {

    const [signedUp, setSignedUp] = useState()
    const [checkedIn, setCheckedIn] = useState()
    const [event, setEvent] = useState()
    // const [myStatus, setMyStatus] = useState()
    const location = useLocation();
    const [error, setError] =  useState(location.state?.error)
    const [loading, setLoading] = useState(true)
    const history = useHistory();
    const { eventId } = useParams();
    const { url } = useRouteMatch();


    // after initial render, 
    // ... clear the history state with a possible error message passed by other component
    useEffect(() => {
        history.replace()
    },[])
    
    useEffect(() => { 
        const fetchEvent = async () => {
            await getEventDetail(eventId)
                .then(results => {
                    setEvent(results.data)
                    if (["checked_in"].includes(results.data.participating_status)) {
                        setCheckedIn(true)
                    }
                    else {
                        setCheckedIn(false)
                    }
                })
                .catch((err) => console.error(err.response))
            setLoading(false)
        }       
        fetchEvent()
      },[signedUp])

    const handleEventSignup = async (cancel) => {
        try {
            if (cancel) {
                await eventCancelSignup(eventId)
                setSignedUp(false)
            }
            else { 
                await eventSignup(eventId)
                setSignedUp(true)
            }
        }
        catch (err) {
          console.error(err)
        }
      }

    const handleBeginCheckIn = async () => {
        try {
          const result = await eventCheckIn(eventId)
          setCheckedIn(true)
        }
        catch (err) {
          console.error(err)
          setCheckedIn(false)
        }
      }

    const goToDating = async () => {
        history.push(`${url}/dating`)
    }

    const { path } = useRouteMatch(); 
    const myStatus = event?.participating_status

    // if negative, event already started; if positive, event hasn't yet started
    const diff = Math.floor((new Date(event?.start_time) - new Date()) / 1000 / 60) 
    // can check in if the event will start in 20 mins or less or started 30 mins or less ago 
    const canCheckInBasedOnTime = diff <= 20 && diff >= -30
    
    if (loading) return <p>Loading...</p>
    return (
        <>
        <Switch>
            <Route path={path} exact>
                    <BackButton />
                    { event ? ( <EventDetail 
                        event={event}
                        handleEventSignup={handleEventSignup}
                        signedUp={signedUp}
                    /> ) : <p>Event not found.</p>}
            </Route>
            <Route path={`${path}/check-in`} exact>
                { ["in_progress"].includes(event?.status) && (myStatus === "participating" || (myStatus === "checked_in" && diff < 0)) ? 
                <Redirect to={`${url}/dating`} />
                :
                <BackButton />}
                { event?.status === "checking_in" || (event?.status === "in_progress")
                // disabling this as a condition for now as it adds another variable/complexity/failure point 
                //  && canCheckInBasedOnTime))
                  ? 
                    !checkedIn ? 
                        <BeginCheckIn
                            event={event}
                            handleBeginCheckIn={handleBeginCheckIn}
                            error={() => 
                                error && (
                                    <Alert color="warning">
                                    <h6>{error}</h6>
                                    </Alert>
                                )}
                        /> : 
                    <CompletedCheckIn
                        goToDating={goToDating}
                        error={() => 
                            error && (
                                <Alert color="warning">
                                <h6>{error}</h6>
                                </Alert>
                            )}
                    />
                : 
                event?.status === "not_started" || (event?.status === "checking_in" && !canCheckInBasedOnTime) ? <p>Check-in hasn't started yet. Please see a host for help.</p> :
                event?.status === "in_progress" && diff <= -15 ? <p>Sorry, the event has already started. Please see a host for help.</p> :
                event?.status === "complete" ? <p>Invalid event. Please try another event.</p> 
                : ( <>    
                    <p>Issue found. Please show your phone with this screen showing to a host.</p>

                    <p>Diagnostic info:</p>
                    <ul>
                        <li>Event status: {event?.status}</li>
                        <li>Your status: {event?.participating_status}</li>
                        <li>Event start time (local time zone): {new Date(event?.start_time).toString()}</li>
                    </ul>
                </> )
                 }
            </Route>
                
        </Switch>
        </>
    )  
}

const BackButton = () => {
    const history = useHistory();
    return ( 
        <button
            className="btn btn-link mb-2"
            onClick={() => history.goBack()}>
        &lt;&lt; Back</button>
    )
}

   
        

export default EventsRouter;
import React, { useEffect, useState } from "react";
import {
    Container,
    Alert
  } from "reactstrap";
import { fetchProfile } from "../api"
import { useParams, useHistory, useRouteMatch, Link } from "react-router-dom";
import { renderLink } from "../utilities/misc"

const EventDetail = ({ event, handleEventSignup, signedUp }) => {
  // const [event, setEvent] = useState()
  const [notifySignedUp, setNotifySignedUp] = useState()
  const [submitting, setSubmitting] = useState()
  const history = useHistory();
  const { url } = useRouteMatch();


  const handleClickSignUp = async () => {
    setSubmitting(true)
    const profile = await fetchProfile()
    if (!profile.data.profile_complete) {
        history.push(`/profile`,
          {error: "Before signing up for an event, please complete your profile."}
        )
    }
    else {
      await handleEventSignup()
      setNotifySignedUp("You've signed up!")
      setSubmitting(false)
    }
  }

  const handleClickCancelSignUp = async () => {
    setSubmitting(true)
    await handleEventSignup("cancel")
    setNotifySignedUp("You've canceled signup!")
    setSubmitting(false)
  }
    
  let dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  let newSignedUp = signedUp === true && true
  if (!event) {
    return ( <div>Loading...</div> )
  }
  return (
    <Container className="container mt-3">
      { (event.participating_status !== false && ["in_progress", "checking_in", "show_matches", "complete"].includes(event.status)) && (
          <Alert color="info" className="w-75">
            <h5>At the event?</h5>
            {renderLink(event)}
          </Alert>
        )}

        <h3 className="mb-1">{ !event.participating_status ? ( "Sign up for") : ""} {event.event_name}</h3>
        { event.status === "complete" && <span className="badge badge-success mb-2">Past Event</span> } 
        { (event.status !== "complete" && event.participating_status !== false ) && <span className="badge badge-success mb-2 p-1">Signed Up</span> }
        <ul>
          <li>
            <strong>Date/time</strong>: {new Date(event.start_time).toLocaleString("en-US", dateOptions)} ({Intl.DateTimeFormat().resolvedOptions().timeZone} time zone)
          </li>
          <li>
            <strong>Location:</strong> <a href={event.venue?.google_maps_url} target="_blank">{event.venue?.venue_name}</a>
          </li>
          <li>
            <strong>Description:</strong> 
            <div dangerouslySetInnerHTML={{__html: event.description}} />
          </li>
        </ul>
        { notifySignedUp && (
          <Alert color="success" className="w-75 text-center mx-auto">
            {notifySignedUp} 
          </Alert>
        )}
        <div className="mt-4 text-center">
          { !event.participating_status ? (
            <button className="btn btn-primary" onClick={handleClickSignUp} disabled={submitting}>Sign up now!</button>
          ) : 
          ["not_started", null].includes(event.status) ? (<button className="btn btn-primary" onClick={handleClickCancelSignUp}>Cancel signup</button>)
        : ""}
        </div>
        </Container>)
}

export default EventDetail

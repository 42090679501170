import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Alert
} from "reactstrap";
import {
  useHistory, useRouteMatch, useParams, useLocation
} from "react-router-dom";

const CompletedCheckIn = ({
  goToDating,
  eventStatus,
  error
}) => {

    const [btnDisabled, setBtnDisabled] = useState(true)
    const [status, setStatus] = useState()
    // const [password, setPassword] = useState()
    const params = useParams()
    const history = useHistory();
    const location = useLocation();
    console.log(location)
    const { url, path } = useRouteMatch();

    const handleChange = password => {
      password === "funtimes" ? setBtnDisabled(false) : setBtnDisabled(true)
    }

  //   const handleSubmit = async () => {
  //     console.log('handlesubmit ran!')
  //     fetchEvent().then(results => {
  //       console.log(`handlesubmit in completedcheckin`)
  //       console.log(results)
  //     console.log(eventStatus)
  //     if (eventStatus !== "active") {
  //       setStatus(<>Event hasn't started yet. Please stand by for instructions from the host.</>)
  //       // return false;
  //     }
  //     else {
  //       console.log('event is active')
  //       history.push(`/events/${params.eventId}/dating`)
  //     }
  //   })
  // }
    console.log(`eventStatus is ${eventStatus}`)

  return (
    <Container className="mt-3">
      <Row>
        <Col>
        {error()}
          <div className="card">
            <h4 className="card-header">
             You've checked in!
            </h4>
            <div className="card-body">
                <div className="w-100 mb-3">
                    Please hang tight for more instructions from the organizer.
                </div>
              <h5>A refresher on how dating works...</h5>
              <ol style={{ fontSize: ".9rem", lineHeight: 1.6 }}>
              <li style={{ marginTop: "4px" }}>
                  <strong>Date</strong> You'll see your date's profile so you have something to talk about.
                </li>
                {/* <li style={{ marginTop: "4px" }}>
                    The app will tell you when the date is starting and the countdown timer will start automatically so you'll know how much time you have left.
                </li> */}
                <li style={{ marginTop: "4px" }}>
                    <strong>Rate</strong> You'll fill out the date scorecard to select whether you liked your date.
                </li>
                <li style={{ marginTop: "4px" }}>
                    <strong>Match</strong> After the final date, you'll see tonight's matches!
                </li>
              </ol>
              <hr />
              <h6 className="text-center mb-3">Your host will announce the event code shortly.</h6>
              {status}
                <div className="form-row">
                  <div className="col-sm-12 col-md-6 mx-auto">
                  <input
                  type="text"
                  id="password"
                  placeholder="Event code from organizer"
                  className="form-control"
                  autoComplete="off"
                  onChange={e => handleChange(e.target.value)}
                  />

                  <button 
                    type="button"
                    size="lg"
                    className="mx-auto btn btn-lg btn-primary form-control mt-3"
                    style={{textAlign: "center", width: "100%", display: "block", marginBottom: "10px"}}
                    onClick={goToDating}
                    disabled={btnDisabled}>
                    Submit
                  </button>
                  </div>
                  {eventStatus}
                </div>
                {/* <div className="text-center">If you need any help, please don't hesitate to ask the event organizer, Robert Geller.</div> */}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CompletedCheckIn;

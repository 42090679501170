import React from "react";
import PropTypes from "prop-types";
import InfoMessage from "../InfoMessage";
import { withRouter } from "react-router";

class SignupForm extends React.Component {
  state = {
    email: "",
    password: "",
    confirm_password: "",
    // signupError: this.props.form_errors.signup
    signupError: "",
    signupSuccess: false,
  };


  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };


  handle_submit = async e => {
    this.setState({
      signupError: ""
    })
    e.preventDefault()
    if (this.state.password !== this.state.confirm_password) {
      this.setState({
        signupError: "Passwords don't match."
      })
    }
    else {
      const submit = await this.props.handle_signup(e, this.state)
      console.log("submit status" + submit)
      if (this.props.form_errors.signup) {
        console.log("error!")
        console.log(this.props.form_errors)
      }
    }
  }

  render() {
    // const { signup: signupError } = this.props.form_errors;
    const propsError  = this.props.form_errors.signup
    const localError  = this.state.signupError;
    const { match, location, history } = this.props;

    // console.log(`props form errors: ${this.props.form_errors.signup}`)
    // const signupError = this.state.signupError
    return this.state.signupSuccess ?  history.push('/') : (
      <div className="container login-container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-11 login-form-1">
            <h3>Sign Up</h3>
           
            <form
              className={(propsError || localError) && "pt-0"}
              // onSubmit={e => this.props.handle_signup(e, this.state)}
              onSubmit={e => this.handle_submit(e)}
            >
               
                <div className="row justify-content-center">
                  <h6 className="signup-error" style={{color: "red"}}>
                        {this.state.signupError ? this.state.signupError : 
                        (propsError && propsError.email) ? propsError.email : ""}
                      </h6>
                </div>
            
              {/* {signupError && (<div className="pt-0 pb-2 mb-2" style={{color: "red"}}>{signupError && signupError.email}</div>) } */}
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address *"
                  name="email"
                  value={this.state.email}
                  onChange={this.handle_change}
                  autoComplete="username"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password *"
                  name="password"
                  value={this.state.password}
                  onChange={this.handle_change}
                  autoComplete="new-password"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password *"
                  name="confirm_password"
                  value={this.state.confirm_password}
                  onChange={this.handle_change}
                  autoComplete="new-password"
                  required
                />
              </div>

              <div className="form-group row justify-content-center">
                <input type="submit" className="btnSubmit" value="Sign up!" />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

// export default SignupForm;
export const SignupFormWithRouter = withRouter(SignupForm);

SignupForm.propTypes = {
  handle_signup: PropTypes.func.isRequired
};

import {  Container } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { listMatches } from "./api.js"
import'./ShowDateMatches.css';

const serverHost = process.env.REACT_APP_SERVER_HOST;

const ShowDateMatches = ({
    // dateMatches: tempMatches
  }) => {

    const [dateMatches, setDateMatches] = useState() 
    const [loading, setLoading] = useState(true)
    const { eventId } = useParams()
    console.log(useParams())
    console.log(eventId)

    useEffect(() => {
        console.log(loading)
        console.log(dateMatches)
        const getDateMatches = async () => {
            try {
              const result = await listMatches(eventId);
              setDateMatches(result.data)
            }
            catch (error) {
              console.log("ERROR!")
              console.log(error)
            }
        setLoading(false)
        }
        if (!dateMatches) {
            getDateMatches()
        }
        else {
            setLoading(false)
        }
    },[])

    const matchCatLookup = {
        like_friend: "Friend",
        like_business: "Business",
        like_romantic: "Romantic"
    } 
    const matches = (dateMatches && dateMatches.length !== 0) ? dateMatches.map((match, key) => {
        let matchCats = []
        match.match_categories.map((cat) => {
            matchCats.push(matchCatLookup[cat])
        })
        return (
            <tr key={key}>
                <td>
                    <img
                    src={serverHost+match.date_profile.profile_picture}
                    className="img-fluid"
                    style={{
                        maxHeight: "150px"
                    }}
                    />
                </td>
                <td>
                    {match.date_profile.first_name}
                </td>
                <td>
                    
                    {matchCats.join(', ')}
                </td>
                <td>
                    {match.date_profile.email}
                </td>
                {/* <td>
                    <a href={`tel:${match.date_profile.phone_number}`}>{match.date_profile.phone_number}</a>
                </td> */}
                <td>
                    {match.my_rating_notes}
                </td>
            </tr>
        )
    }) : []

    return (
        <Container>
        <div className="mt-3">
            { loading ? "Loading..." : 
            matches.length !== 0 ? 
            (
            <>
            <h5 className="text-center mb-4 text-dark">
                Here are your matches for the night!
            </h5>
             <table>
                <thead>
                    <tr>
                        <th>Profile picture</th>
                        <th>First name</th>
                        <th>Type of match</th>
                        <th>Email</th>
                        {/* <th>Phone number</th> */}
                        <th>Your notes</th>
                    </tr>
                </thead>
                <tbody>
                    {matches}
                </tbody>
            </table>
            </> ) : (
                <div>
                    <h4 className="text-center">Sorry, you have no matches for tonight.</h4>
                    <hr />
                    <h5>A quick note...</h5>
                    <p>
                        The primary goal should be to have fun! Of course, a possible secondary benefit is meeting someone, but that will be far likelier to happen
                        if you come to events frequently and if you're having fun, which makes you even more radiant, attractive, and open to connection.
                        </p>
                        <p>
                            Thank you for attending and I hope to see you at a future event soon!
                        </p>
                        <p>
                        - Robert
                    </p>
                </div>
            )
            }
        </div>
      </Container>
        )
    
      }
    
export default ShowDateMatches;
import React, { Component } from "react";

import axios from "axios";
import "./App.css";
// import VideoChat from './VideoChat';
import { SpeedDate } from "./NewSpeedDate";
// import FindDateScreen from "./Dating/FindDateScreen";
import NoDate from "./NoDate";
// import EventList from "./EventList";
import EventsRouter from "./Events/EventsRouter"
import EventDetail from "./Events/EventDetail";
import EventsList from "./Events/EventsList";
import Nav from "./components/Nav";
import DatesEnded from "./DatesEnded";
import { SignupFormWithRouter as SignupForm } from "./components/SignupForm";
import LoginForm from "./components/LoginForm";
import Profile from "./components/Profile";
import DateRating from "./Dating/DateRating";
import EventStarting from "./EventStarting";
import BeginCheckIn from "./BeginCheckIn";
import CompletedCheckIn from "./CompletedCheckIn";
import DateFailed from "./components/DateFailed";
import ShowDateMatches from "./ShowDateMatches";
import Intermission from "./Intermission";
import { Container, Row, Col } from "reactstrap";
import InfoMessage from "./InfoMessage";
import EventEnded from "./EventEnded";

import { Route, Switch, Redirect, useLocation, withRouter, useHistory } from "react-router-dom";
import { PrivateRoute } from "./Routes.js"
// import { withRouter } from 'react-router-dom'

import { AdminRouter } from "./admin/AdminMain"

import { NETWORK_ERROR } from "./errors";

import { getServerTime, httpClient } from "./api.js";

//probably it will be better to use react functional hooks react style also here in App.js

const initialState = {
  // displayed_form: "", // nicer to use react router
      logged_in: localStorage.getItem("token") ? true : false,
      auth_token: localStorage.getItem("token") || "",
      email: "",
      first_name: "",
      form_errors: {},
      // OLD : room_name_date_id: "",
      eventInfo: "",
      error: "",
      profile: "",
      serverTime: "",
      profileError: "",
      networkError: "",
      lobbyError: "",
      time: "",
      dateOrder: parseInt(window.localStorage.getItem("dateOrder")) || 1,
      // dateOrder: 1,
      eventElapsedTime: "0",
      lastDateName: "",
      loading: false,
}

class App extends Component {
  constructor(props) {
    super(props);
    let initial
    this.state = initialState
    // this.state = {
    //   displayed_form: "", // nicer to use react router
    //   logged_in: localStorage.getItem("token") ? true : false,
    //   email: "",
    //   first_name: "",
    //   form_errors: {},
    //   // OLD : room_name_date_id: "",
    //   eventInfo: "",
    //   error: "",
    //   profileError: "",
    //   networkError: "",
    //   lobbyError: "",
    //   time: "",
    //   dateOrder: 0,
    //   eventElapsedTime: "0",
    //   lastDateName: ""
    // };
  }

  resetState() {
    this.setState(initialState)
  }

  componentDidMount = () => {
    // Sentry.configureScope(
    //   scope => scope
    //     .setUser({"email": "john.doe@example.com"})
    // );
  }

  calculateTime = async () => {
    let response = await getServerTime()
    let serverTime = response.data.server_time
    // let clientTime = Date.now()
    let clientTime = new Date()
    // console.log(serverTime)
    // console.log(Date.now())

    let offset = new Date(serverTime).getTime() - clientTime.getTime()
    // console.log(offset) 
    // console.log(`clientTime is ${clientTime}`)
    // clientTime.setTime(clientTime.getTime() + offset);
    // console.log(`clientTime is ${clientTime}`)
    this.setState({
      // time: clientTime,
      timeOffset: offset
    })
    // console.log(this.state.time)
  }


  //it is better practise to use react context for storing login state and handaling login/logout
  //https://kentcdodds.com/blog/authentication-in-react-applications
  handle_login = async (e, data) => {
    console.log(data)
    e.preventDefault();
    window.scrollTo(0, 0)
  axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/token-auth/`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(json => {
        localStorage.setItem("token", json.data.token);
        httpClient.defaults.headers.common['Authorization'] = `JWT ${json.data.token}`
        // this.checkProfile()
        // this.checkForCurrentEvent();
        this.setState({
          logged_in: true,
        });

        this.props.history.push('/events')
      })
      .catch(error => {
        console.log('login failed!')
        console.log(error)
        if (error.message === "Network Error") {
          this.handleNetworkError();
        } else if (error.response.status === 401) {
          console.log(error.response)
          this.handle_token_error(
            error.response.status,
            "Sorry, you've been logged out due to inactivity. Please log in again."
          );
        } else if (error.response.status === 400) {
          this.setState({ form_errors: { login: "That email address and password combination didn't work. Please try again." } })
        } else {
          this.setState({ form_errors: { login: error.response.data } }); 
        }
      });
  };

  //in js they usually dont use this_style_of_naming like in python, only camelCase
  handle_signup = (e, data) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_SERVER_HOST}/api/user/`,
        // `https://reqres.in/api/users/`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(json => {
        localStorage.setItem("token", json.data.token);
        this.setState({
          logged_in: true,
          email: json.email,
          auth_token: json.data.token,
          // displayed_form: "profile",
          // profileError: true,
        });
      })
      .catch(error => {
        if (error.message === "Network Error") {
          this.handleNetworkError();
        } else if (error.response.status == 401) {
          this.handle_token_error(
            error.response.status,
            "Sorry, you've been logged out due to inactivity. Please log in again."
          );
        }
        else if (error.response.status === 400) {
          console.log(error)
          console.log(error.message)
          console.log(error.response)
          // return "error"
          this.setState({ 
            form_errors: { signup: error.response.data },
            logged_in: false,
            displayed_form: "signup" 
          }); 
        } 
        else {
          console.log(error)
          console.log(error.message)
          console.log(error.response)
          this.setState({
            logged_in: false,
            displayed_form: "signup",
            form_errors: { signup: error.response.data ? error.response.data : ""}
          });
        }
      });
  };


  handle_logout = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("dateOrder");
    localStorage.clear()
    this.resetState()
    this.setState({ logged_in: false })
    this.props.history.push('/login')
  };


  handle_token_error = (error, errorMessage) => {
    this.setState({
      logged_in: false,
      form_errors: { login_error: errorMessage }
    });
    this.display_form("login");
  };

  display_form = form => {
    this.setState({
      displayed_form: form,
      error: ""
    });
  };

  handle_token = token => {
    this.setState({
      event_token: token
    });
  };


  handleNetworkError = (message = NETWORK_ERROR) => {
    console.log("handlenetworkerror called!")
    this.setState({
      displayed_form: "network_error",
      networkError: message
    });
  };

  render() {
    // let form;
    // switch (this.state.displayed_form) {
    //   case "network_error":
    //     form = (
    //       <div className="container">
    //         <InfoMessage
    //           messageType="warning"
    //           content={() => <h5>{this.state.networkError}</h5>}
    //           style={{ marginTop: "2rem" }}
    //         />
    //       </div>
    //     );
    //     break;
    //     case "error":
    //       form = (
    //         <div className="container">
    //           <InfoMessage
    //             messageType="info"
    //             content={() => <h3>{this.state.error}</h3>}
    //             style={{ marginTop: "2rem" }}
    //           />
    //         </div>
    //       );
    //       break;
    //     case "profile":
    //       form = (
    //         <Redirect to='/profile' />
    //       );
    //       break;
    //     case "loading":
    //       form = (
    //         <div className="container">
    //           Loading...
    //         </div>
    //       )
    //       break;
    //   default:
    //     form = (
    //       <div className="container">
    //         Loading...
    //       </div>
    //     )
    // }

    const appStyle = {
      height: "100vh"
    }


    const { match, location, history } = this.props
    if (this.state.loading) { return ( <>Loading...</>) }
    return (
      <div style={appStyle}>
        {/* { (this.state.displayed_form != "loading" && !this.state.networkError) ?  */}
          {/* (  */}
            <Nav
            display_form={this.display_form}
            handle_logout={this.handle_logout}
            first_name={this.state.first_name}
          /> 
          {/* ) : "" */}
    {/* }  */}
     <Switch>
       {/* redirect trailing-slash URLs to non-trailing-slash URLs */}
        {/* <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} /> */}
          <PrivateRoute path="/" loggedIn={this.state.logged_in} exact>
            <Redirect to="/events" />
          </PrivateRoute>
          <Route path="/admin">
            <AdminRouter />
          </Route>
          <Route path="/login">
            <LoginForm
              handle_login={this.handle_login}
              form_errors={this.state.form_errors}
            />
          </Route>
          <Route exact path="/signup">
            {this.state.logged_in ? <Redirect to="/profile" /> :
            <SignupForm
              handle_signup={this.handle_signup}
              form_errors={this.state.form_errors}
            />}
          </Route>
          <PrivateRoute path="/profile" loggedIn={this.state.logged_in}>
              <Profile
                profile={this.state.profile}
                auth_token={this.state.auth_token}
                handle_token_error={this.handle_token_error}
                error={this.state.profileError}
                handleNetworkError={this.handleNetworkError} 
              />
          </PrivateRoute>
          
          <PrivateRoute path="/events" loggedIn={this.state.logged_in}>
            <EventsRouter />
          </PrivateRoute>
          
      
          {/* Per react router docs (https://reactrouter.com/web/api/Route/path-string-string),
          routes without a path always match... so if path isn't /admin, it will render the form
          just as before */}
          <Route>
            <>404 - Not found.</>
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);

import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import {
  useParams
} from "react-router-dom";

const NoDate = ({
  startNextDate,
  }) => {
    
    const roundNumber = parseInt(useParams().roundNumber)
    return (
    <React.Fragment>
    
    <div className="mt-3 h-75 w-75 mx-auto text-center">
        <h3>No date this round.</h3>
        <p>
            Feel free to grab a drink, but be sure to be back in 4 minutes sharp so you can participate in the next round!
        </p>
        <div className="mx-auto text-center mt-3">
          <button
          type="button"
          className="btn btn-primary"
          onClick={e => startNextDate(roundNumber+1)}
          >
            Click here when the host announces the next round!
          </button>
        </div>
    </div>
  </React.Fragment>
    )

  }

  export default NoDate;
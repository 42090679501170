import React, { useEffect, useState } from "react";
import {
    Container,
    Alert
  } from "reactstrap";
import { getEventList } from "../api"
import { useRouteMatch, Link, useHistory, useParams } from "react-router-dom";
import { renderLink } from "../utilities/misc"

const EventsList = () => {
  // const { url } = useRouteMatch
  const history = useHistory();
  const [events, setEvents] = useState([])
  const [activeEvent, setActiveEvent] = useState()

    // later: could put event-fetching logic into its own React component, which simply calls the api
    // and renders its children with the prop of whatever the api call results are 
    useEffect(() => {
      console.log("running effect in EventsList!")
      console.log(`history.location in eventslist:`)
      console.log(history.location)
      const fetch = async () => {
        console.log('fetch running!')
          getEventList()
              .then(results => {
                console.log('results in eventlist:')
                console.log(results.data)
                  setEvents(results.data)
                results.data.forEach(event => {
                  if (event.participating_status !== false && 
                    ["in_progress", "checking_in", "show_matches", "complete"].includes(event.status))
                    { 
                      console.log(`${event.event_name} is active!`); 
                      setActiveEvent(event) 
                    }
                })
                  // setLoading(false)
              })
              .catch((err) => console.log(err.response))
      }
        fetch()
    
    },[])

console.log(activeEvent)
const otherEvents = 
  events.filter((event) => event.participating_status === false)
    .map((event) => (
      <li key={event.id}>
        <Link to={`/events/${event.id}`}>
        {event.event_name} - {new Date(event.start_time).toLocaleString()}
      </Link>
      </li>
    ))

const myEventsList =  
  events.filter((event) => event.participating_status !== false && event.status !== "complete")
      .map((event) => {
        return (
        <li key={event.id}>
          <Link to={`/events/${event.id}`}>
            {event.event_name} - {new Date(event.start_time).toLocaleString()}
          </Link>
        </li> 
        )
      })

const myCompletedEvents = 
  events.filter((event) => (event.status === "complete" && event.participating_status !== false))
    .map((event) => {
      return (
      <li key={event.id}>
        <Link to={`/events/${event.id}`}>
          {event.event_name} - {new Date(event.start_time).toLocaleString()}
        </Link>
      </li> 
      )
    })

  if (!events) { return (<>Loading...</>) }
  return (<Container className="container mt-5">
        <h2>Welcome to speed dating!</h2>
        
        { activeEvent && (
          <Alert color="info">
            <h5>At the <strong>{activeEvent.event_name}</strong> event?</h5>
            {renderLink(activeEvent)}
          </Alert>
        )}

        <h5 className="mt-3 mb-2">Upcoming events you're signed up for</h5>
        <p className="text-muted">Once you're at the venue, click on an event to check in.</p>
        {myEventsList.length > 0 ? <ul>
          {/* {myEventList} */}
          {myEventsList}
        </ul> : "You aren't signed up for any events."}
        <hr />
        {myCompletedEvents.length > 0 && (
        <>
        <h5 className="mt-3 mb-2">Past events you've participated in</h5>
        <ul>
          {myCompletedEvents}
        </ul>
        </>
        )}
        
        <h5 className="mt-3">
          Other events happening in the next month
        </h5>
        {otherEvents.length > 0 ? <ul>
          {otherEvents}
        </ul> : "There are no upcoming events that you aren't already signed up for." }
        <p>
          <span className="small text-muted">All times are in your device's local time zone, which is currently {Intl.DateTimeFormat().resolvedOptions().timeZone}.</span>
        </p>
        </Container>)
}

export default EventsList

import React, { useEffect, useState } from "react";
import { adminGetEventList } from '../api.js'
import AdminEvent from './AdminEvent'
import {
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,
    useHistory
  } from "react-router-dom";
const serverHost = process.env.REACT_APP_SERVER_HOST;

export const AdminRouter = () => {
    const { url, path } = useRouteMatch();
    let history = useHistory();
    return (
    <div className="container mt-3">
        <Switch>
            <Route path={[path, `${path}/events`]} exact>
                <AdminMain />
            </Route>
            <Route path={`${path}/events/:eventId`}>
                <button className="btn btn-link mb-2" onClick={() => history.goBack()}>&lt;&lt; Back</button>
                <AdminEvent />
            </Route>
            <Route>
                404 - Not found.
            </Route>
            {/* <Redirect  */}
        </Switch>
    </div>
    )
}

const AdminMain = () => {
    const [events, setEvents] = useState()
    const { url, path } = useRouteMatch();

    useEffect(() => {
        const getEvents = async () => {
            console.log('getevents called!')
            let result = await adminGetEventList()
            console.log(result.data)
            setEvents(result.data)
        }
        getEvents()
    }, [])

    const localeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    console.log(url.replace("events", ""))
    const linkTo = url.includes("events") ? url.replace("events", "") : `${url}/`
    return (
        <div className="container mt-3">
        <h3>Here are all the events in the database:</h3>
        <ul>
            {events ? events.map((event,i) => (
                <li key={i}>
                    <Link to={`${linkTo}events/${event.id}`}>{event.event_name} ({new Date(event.start_time).toLocaleString("en-US", localeOptions)})</Link>
                </li>
            )) : ""}
        </ul>
        
        </div>
    )
}

export default AdminMain;
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// import './index.css';
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Sentry is used for debugging/logging
Sentry.init({
    dsn: "https://45af2c8fbb0a4e4aae3b3982f1ec29ac@o1006761.ingest.sentry.io/5967117",
    integrations: [new Integrations.BrowserTracing({ tracingOrigins: ["*"] })],
    release: "a82309c9d8c620f6499bd5127f30658265ce3fff",
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });


ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import PropTypes from "prop-types";
import InfoMessage from "../InfoMessage";

class LoginForm extends React.Component {
  state = {
    email: "",
    password: "",
    forgetPassword: false
  };

  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };

  render() {
    const { forgetPassword } = this.state;
    const { login: loginError } = this.props.form_errors;
    return (
      <div className="container login-container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-11 login-form-1">
            <h3>Log In</h3>
           
            <form
              className={loginError && "pt-0"}
              onSubmit={e => this.props.handle_login(e, this.state)}
            >
               {loginError && (
                <div className="row justify-content-center">
                  <h6 className="login-error">
                        {loginError
                          ? loginError
                          : "Login error"}
                      </h6>
                </div>
              )}
              
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address *"
                  name="email"
                  value={this.state.email}
                  onChange={this.handle_change}
                  autoComplete="username"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password *"
                  name="password"
                  value={this.state.password}
                  onChange={this.handle_change}
                  autoComplete="current-password"
                  required
                />
              </div>

              <div className="form-group row justify-content-center">
                <input type="submit" className="btnSubmit" value="Login" />
              </div>
              <div className="form-group row justify-content-center">
                {forgetPassword ? (
                  <span className="text-center">
                    If you can't remember your password, please email
                    us at <a href="mailto:robert@hellomatch.us">robert@hellomatch.us</a> from the email address you use to sign in. 
                  </span>
                ) : (
                  <a
                    href="#"
                    onClick={() => this.setState({ forgetPassword: true })}
                    className="ForgetPwd"
                  >
                    Forget Password?
                  </a>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm;

LoginForm.propTypes = {
  handle_login: PropTypes.func.isRequired
};

import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  CardText,
  CardHeader,
  Col,
  Button,
  Form,
  Media,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import CountdownTimer from "../CountdownTimer";

import { RATING_LENGTH } from "../config";

// import './App.css'
const serverHost = process.env.REACT_APP_SERVER_HOST;

const DateRating = ({
  date_id,
  lastDateName,
  serverTime,
  handleRateDate,
  dateOrder,
  handleNetworkError,
  handle_token_error,
  speedDateID,
  dateRatingPeriod,
  timeOffset,
}) => {
  const [formState, setFormState] = useState({
    like_friend: "",
    like_romantic: "",
    like_business: "",
    rating_notes: "",
  });

  const [buttonDisabled, setButtonDisabled] = useState(false);
  
  // // PROD VALUE:
  // const [finishRatingTime, setFinishRatingTime] = useState(
  //   moment(moment()).add(dateRatingPeriod, "seconds")
  // );
  // // END PROD VALUE

  const [isSubmitted, setIsSubmitted] = useState(false);

  // const [timeLeft, setTimeLeft] = useState()
  // const countdownFrom = useRef(Date.now()+timeOffset+(dateRatingPeriod*1000))
  // console.log(`dateRatingPeriod is ${dateRatingPeriod}`)
  // console.log(`counting down from ${new Date(countdownFrom.current)}`)
  // console.log(`timeOffset is ${timeOffset}`)

  // start countdown timer for DateRating
  // useEffect(() => {
  //     let nowTime = Date.now()+timeOffset
  //     let timeout
  //     const diff = countdownFrom.current - nowTime
      
  //     console.log(`diff is ${diff}`)
  //     if (diff > 0) {
  //       console.log("dateRating diff is greater than 0")
  //         timeout = setTimeout(() => {
  //             setTimeLeft(diff / 1000);
  //         }, 1000-(new Date(nowTime).getMilliseconds()));
  //     } else {
  //         setTimeout(() => {
  //             handleRateDate();
  //         }, 2000);
  //     }
  //     return () => {
  //       clearTimeout(timeout);
  //     };
  // }, [timeLeft]);


  // // start countdown timer for date
  // useEffect(() => {
  //   console.log("useEffect in DateRating called!")
  //   const diff = moment(finishRatingTime).diff(moment());
  //   if (diff > 0) {
  //     setTimeout(() => {
  //       setTimeRemaining(moment(diff).format("s"));
  //     }, 1000);
  //   } else {
  //     setTimeout(() => {
  //       handleRateDate();
  //     }, 2000);
  //   }

  // }, [timeRemaining]);

  const handleChange = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormState({
      ...formState,
      [e.target.name]: value
    });
  };

  const handleSubmit = e => {
    if (buttonDisabled) {
      return;
    }
    setButtonDisabled(true)
    e.preventDefault();
    handleRateDate(formState)
  }


  return (
    <Container className="container mt-5 mb-3">
      {/* <LocalParticipant key={room.localParticipant.sid} participant={room.localParticipant} videoTrack={videoTrack} />*/}
      <div className="row justify-content-center">
        <Col sm={6}>
          {/* <div className="text-center border rounded p-3 mb-3"> */}
            {/* <h5>
              {timeLeft ? (
              <div><span style={{ color: "red" }}>{Math.round(timeLeft)}</span> seconds
              before the next date begins!</div> )
              :""}
            </h5> */}
            {/* <div>
              <div
                className="mt-1 text-center"
                style={{ lineHeight: "0 !important", fontSize: "0.3" }}
              >
                If you don't rate your previous date, you won't be able to match
                with them.
              </div>
            </div>
          </div> */}
        </Col>
      </div>

      <Row className="justify-content-center">
        <Col sm={12} md={{ size: 6 }}>
          <div className="card">
            <h4 className="card-header">Did you like {lastDateName}?</h4>
            <div className="card-body">
                  <p>
                    If you liked {lastDateName}, choose the categories in
                    which you liked them, then click Submit. If you weren't feeling it, simply leave the checkboxes blank.
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup check inline>
                      <div className="text-danger"></div>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="like_friend"
                          id="like_friend"
                          checked={formState.like_friend}
                          onChange={handleChange}
                        />
                        Friend
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <div className="text-danger"></div>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="like_romantic"
                          id="like_romantic"
                          checked={formState.like_romantic}
                          onChange={handleChange}
                        />
                        Romantic
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <div className="text-danger"></div>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="like_business"
                          id="like_business"
                          checked={formState.like_business}
                          onChange={handleChange}
                        />
                        Business
                      </Label>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <div className="text-danger"></div>
                      <Label for="rating_notes" className="mb-0">
                        Date Notes <span style={{color: "#6c757d", marginTop: "10px"}}>(optional)</span>
                      </Label>
                      <div className="text-muted small my-1">
                        Feel free to enter optional notes here to remind you of{" "}
                        your date if you match with them later.
                      </div>
                      <Input
                        type="textarea"
                        name="rating_notes"
                        id="rating_notes"
                        value={formState.rating_notes}
                        onChange={handleChange}
                        placeholder="Cute guy in the white shirt"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Button
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={buttonDisabled}
                      >
                         {buttonDisabled ? 'Please wait...' : 'Submit'}
                      </Button>
                      {/* &nbsp;<Button
                        color="secondary"
                        size="large"
                        type="submit"
                        disabled={buttonDisabled}
                      >
                        Not interested
                      </Button> */}
                    </FormGroup>
                  </Form>
            </div>
          </div>
        </Col>
      </Row>
      {/*<div className="row justify-content-center">
			<Col sm={12} md={{size: 6}} lg={{size: 6}} className="ml-1">
							{/*<div className="time-remaining-info mb-3">{timeRemainingInfo.current}</div>
			<div class="card">
			  <div className="card-header how-it-works">
			    How It Works
			  </div>
			  		<ul className="list-group list-group-flush" style={{fontSize: ".9rem"}}>
					    <li className="list-group-item" >For any matches, you'll receive the category or categories in which you matched and your date's contact information, and you can take it from there!</li>
					    <li className="list-group-item" >If you don't match in any of the same categories, neither you nor your date will be notified.</li>
			  		</ul>
			</div>
			</Col>
			</div>*/}
    </Container>
  );
};

export default DateRating;

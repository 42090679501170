import React, { useEffect, useState, useRef }  from "react";

import {
    useRouteMatch,
    useLocation,
    useHistory,
    useParams
} from "react-router-dom";
import useLocalStorage from "../utilities/useLocalStorage"
import { fetchNextDateInfo, submitDateRating, getEventDetail } from "../api"
import { logout } from "../utilities/misc"

import FindDateScreen from "./FindDateScreen"
import { SpeedDate } from "../NewSpeedDate"
import NoDate from "../NoDate"
import DateRating from "./DateRating"
import EventEnded from "../EventEnded"


const DatingMain = () => {

    let { eventId } = useParams()
    const initialMount = useRef(true)
    const [currentDate, setCurrentDate] = useState()
    const [error, setError] = useState()
    // const [roundNum, setRoundNum] = useState(localStorage.getItem("dateOrder") || 1)
    const [roundNumber, setRoundNumber] = useLocalStorage(`event-${eventId}-roundNumber`, "1");
    // const [roundNumber, setRoundNumber] = useState("1");
    // implement later to resume same screen as where the user left off
    // const [screen, setScreen] = useLocalStorage("screen", "");
    const [screen, setScreen] = useState();
    const [loading, setLoading] = useState(true)

    const history = useHistory();

    function renderFindNextDate() {
        if (loading || !currentDate) return <>Loading...</>
        return ( 
        <FindDateScreen
            dateInfo={currentDate}
            startDate={() => {
                setScreen("SpeedDate")
            }}
            myGender={currentDate.other_date_profile.gender === "male" ? "female" : "male"}
        />
        )
    }

    const renderSpeedDate = () => {
        if (loading) return <>Loading...</>
        return (
            <SpeedDate
                currentDateInfo={currentDate}
                handleEndDate={() => setScreen("DateRating")}
            />
        )
    }

    const renderNoDate = () => {
        return (
        <NoDate
            startNextDate={() => setRoundNumber(roundNumber => parseInt(roundNumber) + 1)}
        />
        )
    }

    const renderDateRating = () => {
        return (
            <DateRating
                lastDateName={currentDate.other_date_profile.first_name}
                handleRateDate={handleRateDate}
          />
        )
    }

    
    const screens = {
        // FindNextDate: renderFindNextDate,
        FindNextDate: renderFindNextDate,
        SpeedDate: renderSpeedDate,
        DateRating: renderDateRating,
        NoDate: renderNoDate,
        loading: () => <>Loading...</>,
        EventEnded: () => <EventEnded />
        // SpeedDate: renderSpeedDate,
        // RateDate: renderRateDate,
    };

    useEffect(() => {
        if (!initialMount.current) {
        startNextDate()
        }
        else {
            initialMount.current = false
        }
    }, [roundNumber])

    const handleRateDate = async formState  => {
        try {
          await submitDateRating(formState, currentDate.id) 
          setRoundNumber(roundNumber => parseInt(roundNumber) + 1)
        //   startNextDate()
          setScreen("loading")
        }
        catch(error) {
          console.log(error)
          if (error.message === "Network Error") {
            console.log("Network error!")
          } else if (error.response.status == 401) {
              alert("You've been logged out due to inactivity. Please log in again.")
              logout(history)
          }
        }
      };


      const startNextDate = async () => {
        try {
            const result = await fetchNextDateInfo(eventId, roundNumber);
            console.log(result.data)
            setCurrentDate(result.data)
            let errorText
            console.log(`roundNumber is ${roundNumber}`)
            switch (result.data.status)
            {
                case "not_started":
                    console.log("not started called!")
                    setCurrentDate(result.data)
                    setScreen("FindNextDate")
                    break;
                case "in_progress":
                    console.log('in progress or complete called!')
                    setCurrentDate(result.data)
                    setScreen("SpeedDate")
                    break;
                case "complete":
                    setScreen(undefined)
                    errorText = `Speed date ${result.data.id} in round ${roundNumber} is already complete. Please show this to the host (host: check server).`
                    console.error(errorText)
                    setError(errorText)
                    break;
                case "skip":
                    console.log('skip called!')
                    setScreen("NoDate")
                    break;
                case null:
                    setScreen(undefined)
                    errorText = `Speed date ${result.data.id} in round ${roundNumber} is null. Please show this to the host (host: check server).`
                    console.error(errorText)
                    setError(errorText)
                    break;
                default:
                    console.log('default called!')
                    console.log(result.data)
                    setError(result.data)
                    break;
            
        }
    }
        catch (err) { 
            if (err.response && err.response.status == 404) {
                setScreen("EventEnded")
            }
            else if (err.response) {
                console.log(err.response)
                setError(err.response.data.status)
            }
            else {
                setError(err)
            }
        }
    setLoading(false)
    }

    // determine whether the event is active. if not, redirect to check-in
    useEffect(() => {
        const fetchEvent = async () => {
            getEventDetail(eventId)
                .then(results => {
                    console.log(results)
                    if (results.data.status === "complete" || results.data.participating_status === "completed") {
                        setScreen("EventEnded")
                        setLoading(false)
                    }
                    else if (results.data.participating_status !== "checked_in" && results.data.participating_status !== "participating") {
                        history.push(`/events/${eventId}/check-in`, 
                            {error: "You haven't checked in yet. Please check in before joining the event."}
                        )
                    }
                    else if (results.data.status === "not_started" || results.data.status === "checking_in") {
                        history.push(`/events/${eventId}/check-in`, 
                            {error: "The event hasn't started yet. Please hang tight for instructions from the host."}
                        )
                    }
                    else {
                        console.log('starting next date!')
                        startNextDate()
                    }
                    })
                .catch((err) => console.log(err.response))
                }
        fetchEvent()
    }, [])


    const Screen = screens[screen]
    
    if (loading) return <>Loading...</>
    return (
        <>
        {error}
        {/* {renderComponent()} */}
        {/* {screens[screen]} */}
        {Screen && <Screen />}
        </>
    )
}


export default DatingMain
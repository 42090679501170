import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Row, Col } from "reactstrap";

const serverHost = process.env.REACT_APP_SERVER_HOST;

const FindDateScreen = ({
  dateInfo,
  startDate,
  myGender
  }) => {


    const otherDateProfile = dateInfo.other_date_profile
    const table_number = dateInfo.table_number
    return (
    <React.Fragment>
    
    <div className="mt-3 h-75 w-100">
        <h3 className="text-center">{myGender === "male" ? ( <>Please go to Table {table_number}</> ) : ( <>Please stay at Table {table_number}</>)}</h3>
        <h4 className="text-center text-primary mt-2">{otherDateProfile.first_name} is your next date!</h4>
        <div className="mx-auto mt-3">
            <img
                src={serverHost+otherDateProfile.profile_picture}
                className="mx-auto img-fluid d-block"
                style={{
                  maxHeight: "50vh",
                  // height: "auto"
                }}
            />
         </div>
        <div className="mx-auto text-center mt-3">
          <button
          type="button"
          className="btn btn-primary"
          onClick={startDate}
          >
            Start the date!
          </button>
        </div>
    </div>
  </React.Fragment>
    )

  }

  export default FindDateScreen;
import React, { useEffect, useState, useRef } from "react";
import { adminGetEventParticipants, adminDeleteEventParticipant, adminGetUserList, adminAddEventParticipant, adminUpdateParticipantStatus } from '../api.js'
import InfoMessage from "../InfoMessage";
import {
    useParams,
    useRouteMatch
  } from "react-router-dom";
  import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
  } from "reactstrap";

  const AdminParticipants = () => {
    const [eventParticipants, setEventParticipants] = useState([])
    const [users, setUsers] = useState([])
    const [formStatus, setFormStatus] = useState()
    const [submitting, setSubmitting] = useState(false)
    const eventParticipantsTable = useRef()
    const { eventId } = useParams();

    const statusChoices = [
        {"display": "Attending", "value": "attending"},
        {"display": "Confirmed", "value": "confirmed"},
        {"display": "Checked In", "value": "checked_in"},
        {"display": "No-Show", "value":"no_show"},
        {"display": "Completed", "value": "completed"},
        {"display":"Participating", "value":"participating"},
    ]

    useEffect(() => {
        const fetchData = async () => {
            // make both needed API calls at once
            let [participants, userList] = await Promise.all([adminGetEventParticipants(eventId), adminGetUserList(eventId)])
            setEventParticipants(participants.data)
            setUsers(userList.data)
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (eventParticipantsTable.current) {
            // Scroll to newly added user
            eventParticipantsTable.current.querySelector('tbody tr:last-child').scrollIntoView()
        }
    }, [eventParticipants])

    const addParticipant = async event => {
        event.preventDefault()

        setSubmitting(true)
        try {
            const userId = event.target.user.value
            const result = await adminAddEventParticipant(eventId, userId)
            const newParticipants = eventParticipants.concat(result.data)
            setEventParticipants(newParticipants)
            setUsers(users.filter(elem => elem.id != userId))
            setFormStatus("Added!")
        }
        catch (err) {
            setFormStatus(`Error: ${err.message}`)
        }
        setSubmitting(false)
    }

    const deleteParticipant = async userId => {
        setSubmitting(true)
        try {
            const userToDel = eventParticipants.find(elem => elem.user == userId)
            userToDel.id = userToDel.user
            const response = await adminDeleteEventParticipant(eventId, userId)
            setEventParticipants(eventParticipants.filter(elem => elem.user !== userId))
            delete userToDel.user
            setUsers(users.concat(userToDel))
        }
        catch (err) { 
            console.log(err)
        }
        setSubmitting(false)
    }

    const changeParticipantStatus = async event => {
        setSubmitting(true)
        // the <select>'s name is set to the userid for easy extraction of userid
        const userId = event.target.name
        try {
            const response = await adminUpdateParticipantStatus(eventId, userId, event.target.value)
        }
        catch (err) {
            console.log(err)
        }
        setSubmitting(false)
    }

    return (
        <>
        <h3>Participants</h3>
        <div
            className="admin-participants"
        >
            {eventParticipants.length !== 0 ? ( 
            <table
                ref={eventParticipantsTable}
            // style={{
            //         // display: "table",
            //         display: "block",
            //         position: "relative",
            //         overflowY: "scroll",
            //         // height: "50vh",
            //         width: "100%",
            //         minHeight: "80px",
            //         }}
                    >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Gender</th>
                        <th>Status</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {eventParticipants.map(participant => {
                        return (
                            <tr key={participant.user}>
                                <td>
                                    {participant.user_profile.first_name}
                                </td>
                                <td>
                                    {participant.user_profile.gender}
                                </td>
                                <td>
                                    <select
                                        name={participant.user}
                                        id="status"
                                        defaultValue={participant.status}
                                        onChange={changeParticipantStatus}
                                        disabled={submitting}
                                    >
                                        <option value="">
                                            --
                                        </option>
                                        {statusChoices.map((status, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={status.value}
                                                >{status.display}</option>
                                            )
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <Button
                                        onClick={() => deleteParticipant(participant.user)}
                                        disabled={submitting}
                                        color="primary"
                                    >Remove</Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table> )
            : "No participants found."}
        </div>
        <Form
        className="w-75 mt-3"
        onSubmit={addParticipant}
        >
            <FormGroup>
                <Label for="user" />
                <select
                        name="user"
                        id="user"
                        // className="profile"
                        required
                    >
                    <option value="" disabled>
                        --
                    </option>
                    {users.map((user, index) => {
                        return (
                            <option key={index} value={user.id}>{user.email} ({user.user_profile.first_name}) - joined {user.date_joined}</option>
                        )
                    })}
                </select>
            </FormGroup>
            <FormGroup>
                <Button
                    color="primary"
                    size="large"
                    type="submit"
                    className="mb-3"
                    disabled={submitting}
                  >
                   Add User to Event
                </Button>
            </FormGroup>
        </Form>
        </>
    )
  }

  export default AdminParticipants
import React, { useEffect, useState } from "react";
import { adminGetEventDetail, adminRunMatching } from '../api.js'
import InfoMessage from "../InfoMessage";
import AdminParticipants from "./AdminParticipants"
import {
    Switch,
    Route,
    NavLink,
    useParams,
    useRouteMatch
  } from "react-router-dom";

import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
  } from "reactstrap";
const serverHost = process.env.REACT_APP_SERVER_HOST;

const AdminManage = ({eventInfo, localeOptions}) => {

    const [numDates, setNumDates] = useState('')
    const [numTables, setNumTables] = useState('')
    const [tableNumStart, setTableNumStart] = useState(1)
    const [matchingFormBtnDisabled, setMatchingFormBtnDisabled] = useState(true)
    const [matchingFormStatus, setMatchingFormStatus] = useState("")

    const handleFormSubmit = async event => {
        event.preventDefault();
        setMatchingFormBtnDisabled(true)
        setMatchingFormStatus("")
        try {
            await adminRunMatching(eventInfo.id, numDates, numTables, tableNumStart)
            setMatchingFormStatus({
                message: "Successfully created speed dates!",
                color: "success"
            })
        }
        catch(err) {
            console.log(err)
            console.log(err.response)
            setMatchingFormStatus({
                message: err.response.data.description,
                color: "danger"
            })
        }
        // setMatchingFormBtnDisabled(false)
     }

    return (
        <>
    <table style={{width: "60%"}}>
        <tbody>
        <tr>
            <td><strong>Event Name</strong></td>
            <td>{eventInfo.event_name}</td>
        </tr>
        <tr>
            <td><strong>Start time</strong></td>
            <td>{new Date(eventInfo.start_time).toLocaleString('en-US', localeOptions)}</td>
        </tr>
        <tr>
            <td><strong>Status</strong></td>
            <td>{eventInfo.status}</td>
        </tr>
        </tbody>
    </table>
    <hr />
    <h4>Run Date Pairing Algorithm</h4>
    <Form
        className="w-75 mt-3"
        onSubmit={handleFormSubmit}
        onChange={e => setMatchingFormBtnDisabled(false)}
    >
        {matchingFormStatus.message ? (
                    <InfoMessage
                      messageType={matchingFormStatus.color}
                      content={() =>
                        <div>
                          {matchingFormStatus.message}
                          {matchingFormStatus.color === 'success' ? <><br /><a href={`${serverHost}/admin/backend/speeddate/`} target="_blank">
                              Click here to view the speed dates!
                            </a></> : ''}
                          </div>}
                    />

                  ) : (
                    ""
                  )}
        <FormGroup className="w-50">
            <Label for="num_rounds">
                How many rounds?
            </Label>
            <Input 
                id="num_rounds"
                name="num_rounds"
                type="number"
                className="profile"
                value={numDates}
                onChange={e => setNumDates(e.target.value)}
                required
            />
        </FormGroup>
        <FormGroup className="w-50">
            <Label for="num_tables">
                How many tables or stations are available?
            </Label>
            <Input 
                id="num_tables"
                name="num_tables"
                type="number"
                className="profile"
                value={numTables}
                onChange={e => setNumTables(e.target.value)}
                required
            />
        </FormGroup>
        <FormGroup className="w-50">
            <Label for="table_num_start">
                What should the first table number be?
            </Label>
            <Input 
                id="table_num_start"
                name="table_num_start"
                type="number"
                className="profile"
                value={tableNumStart}
                onChange={e => setTableNumStart(e.target.value)}
                required
            />
        </FormGroup>
        <FormGroup>
            <Button
                color="primary"
                size="large"
                type="submit"
                className="mb-3"
                disabled={matchingFormBtnDisabled}
            >
                <span className="glyphicon glyphicon-heart" />
                Run Matching
            </Button>
            </FormGroup>
    </Form>
    </>
    )
}

const AdminEvent = () => {
    const localeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const { eventId } = useParams();
    const [eventInfo, setEventInfo] = useState()
    const [loading, setLoading] = useState(true)
    const [networkError, setNetworkError] = useState()
    const { url, path } = useRouteMatch();
    console.log(url)
    console.log(path)

    useEffect(() => {
        const getEventDetail = async () => {
        try {
            let result = await adminGetEventDetail(eventId)
            console.log(result.data)
            setEventInfo(result.data)
            setLoading(false)
        }
        catch (err) {
            if (err.response) {
                console.log(err.response)
            }
            setLoading(false)
            setNetworkError(err.message)
        }
    }
        getEventDetail()
    }, [])

    return (
        <div>
        { loading ? ( "Loading...") : 
            networkError ? networkError :
            !eventInfo ? ( "Event not found." ) :
            (
            <div>
                <h2>Admin</h2>
                <h4>{eventInfo.event_name}</h4>
                    <>
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to={`${url}/manage`} className="nav-link" activeClassName="active">Event Info</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={`${url}/participants`} className="nav-link" activeClassName="active">Participants</NavLink>
                    </li>
                </ul>
            <Switch>
            <Route path={`${path}/participants`} exact>
                <AdminParticipants />
            </Route>
            <Route path={[`${path}/manage`, path]}>
                <AdminManage
                    eventInfo={eventInfo}
                    localeOptions={localeOptions}
                    testProp={url}
                 />
            </Route>
        </Switch>
        </>
        </div> 
        ) }
        </div>
    );
}

export default AdminEvent
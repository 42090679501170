import { Row, Col, Container, Table } from "reactstrap";
import React from "react";

const serverHost = process.env.REACT_APP_SERVER_HOST;

const EventEnded = () => {

    return (
        <Container>
        <div className="mt-3">
            <h5 className="text-center mb-4 text-dark">Speed dating is over for the evening!</h5>
            <div className="text-center">We'll now have open mingling. Your host will tell you how to retrieve your matches!</div>
        </div>
      </Container>
        )
    
      }
    
export default EventEnded;
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  Fragment
} from "react";
import {
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import InfoMessage from "../InfoMessage";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Form,
  Media,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import { fetchProfile } from "../api"

const serverHost = process.env.REACT_APP_SERVER_HOST;

const Profile = ({
  profile,
  auth_token,
  error,
  handleNetworkError,
  handle_token_error,
}) => {

  // const [profile, setProfile] = useState()
  const [firstName, setFirstName] = useState(profile?.first_name || "");
  const [phoneNumber, setPhoneNumber] = useState(profile?.phone_number || "");
  const [age, setAge] = useState(profile?.age || "");
  const [gender, setGender] = useState(profile?.gender || "");
  const [whereFrom, setWhereFrom] = useState(profile?.where_from || "");
  const [occupation, setOccupation] = useState(profile?.occupation || "");
  const [interests, setInterests] = useState(profile?.interests || "");
  const [profilePicturePreview, setProfilePicturePreview] = useState(profile?.profile_picture ? (serverHost+profile?.profile_picture) : "");
  const [profilePictureComplete, setProfilePictureComplete] = useState(profile?.profile_picture ? true : false);
  const [progress, setProgress] = useState(0);

  const [formStatus, setFormStatus] = useState("");
  const [formSubmitting, setFormSubmitting] = useState(false)

  const [profilePicFormStatus, setProfilePicFormStatus] = useState();
  const [profilePictureToUpload, setProfilePictureToUpload] = useState("");
  const [imageUploadError, setImageUploadError] = useState("");

  const [profilePictureMessage, setProfilePictureMessage] = useState({
    __html: ""
  });
  
  const [errors, setErrors] = useState({});
  const [mainError, setMainError] = useState();
  const [isLoading, setIsLoading] = useState(
    "Loading... please hang on a moment"
  );

  const [loading, setLoading] = useState(true)
  const [profFormButtonDisabled, setprofFormButtonDisabled] = useState(true);

  const profileForm = useRef()

  const location = useLocation()

  useEffect(() => {
    const checkSetProfile = async () => {
      let profileVar
      if (!profile) {
        try {
          const result = await fetchProfile(auth_token)
          profileVar = result.data
          setFirstName(profileVar.first_name || "")
          setPhoneNumber(profileVar.phone_number || "");
          setAge(profileVar.age || "");
          setGender(profileVar.gender || "")
          setWhereFrom(profileVar.where_from || "")
          setOccupation(profileVar.occupation || "")
          setInterests(profileVar.interests || "")
          setProfilePicturePreview(profileVar.profile_picture ? (serverHost+profileVar.profile_picture) : "");
          setProfilePictureComplete(profileVar.profile_picture ? true : false)
          setLoading(false)
        }
        catch (err) {
          console.error('fetch profile failed!')
          console.error(err.response)
          console.error(err)
         if (err.message === "Network Error") {
           console.error("network error!")
           setMainError(<>Network error. Please refresh or <a href="mailto:robert@hellomatch.us">email us</a> to let us know. Thank you for your patience.</>)
         } else if (err.response && err.response.status == 401) {
           this.handle_token_error(
             err.response.status,
             "Sorry, you've been logged out due to inactivity. Please log in again."
           );
         }
        }
      }
      // else {
      //   setProfile(profileProp)
      // }
      setLoading(false)
    }
    checkSetProfile()
  }, [])
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const onUploadProgress = progressEvent => {
    console.log("onuploadprogress called!")
    setProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total ))
  }

  const handleFormSubmit = async event => {
    event.preventDefault();
    setFormSubmitting(true)
    setprofFormButtonDisabled(true)
    if (!profilePicturePreview) {
      // setMainError(true)
      setFormStatus({
        message: "Please fix the errors below or reach out to robert@hellomatch.us or 646-854-8488 for help.",
        color: "danger"
      });
      setErrors({...errors, profilePicture: "Please choose a profile picture."})
      setFormSubmitting(false)
      scrollToTop()
      return
    }

    let formData = new FormData(event.target);
    var config = { }

    if (profilePictureToUpload) {
      console.log("uploading profile pic!")
      formData.append("profile_picture", profilePictureToUpload);
    }
    setFormStatus({})
    setErrors({})
    axios
      .put(`${serverHost}/api/user/profile/`, formData, {
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("token")}`
        },onUploadProgress
      })
      .then(response => {
        setFormStatus({
          message: "Successfully saved changes to your profile!",
          color: "success"
        });
        setProfilePictureToUpload("")
        setProgress(0)
        return response.data;
      })
      .catch(error => {
        if (error.message === "Network Error") {
          handleNetworkError();
        } else if (error.response.status == 401) {
          handle_token_error(
            error.response.status,
            "Sorry, you've been logged out due to inactivity. Please log in again."
          );
        } else {
          console.error(error.response);
          console.error(error.response.data.where_from);
          setErrors(error.response.data);
          setFormStatus({
            message: "Please fix the errors below or reach out to robert@hellomatch.us or 646-854-8488 for help.",
            color: "danger"
          });
        }
      });
      setFormSubmitting(false)
      scrollToTop()
    };

  const handleProfilePictureChange = e => {
    var _URL = window.URL || window.webkitURL;
    console.log(_URL)
    // var image
    var file = e.target.files[0];

    console.log(e.target.files[0]);

    const image = new Image();
    image.src = _URL.createObjectURL(file);
    console.log(image.src)
    // setProfilePicturePreview(_URL.createObjectURL(image))
    console.log(image);
    image.onload = () => {
      // if (image.width > 600 || image.height > 600) {
      //   setImageUploadError(`Your profile picture must be less than 600 pixels by 600 pixels.
      //     Please resize your picture and try uploading again.`);
      // } else {
        setImageUploadError("");
        setProfilePicturePreview(_URL.createObjectURL(file));
        setProfilePictureToUpload(file);
    };
  };

  let render;
  if (loading) return ( <div>Loading...</div> )
  return (
    (
      <Container style={{ paddingTop: "20px" }}>
        <h3>Profile</h3>
        <hr />
        {location.state?.error &&
            (
            <Alert color="warning">
            <h6>{location.state.error}</h6>
            </Alert>
        )}
        {mainError ? (
          <Alert color="warning">
          <h6>{mainError}</h6>
          </Alert>
        ) :

        <Row>

          <Col sm={{ size: 7 }} className="mt-3 mb-2">


            <Form
              onChange={e => setprofFormButtonDisabled(false)}
              onSubmit={handleFormSubmit}
              ref={profileForm}
            >
              <div className="w-75 ml-2">
                
                  {formStatus.message ? (
                    <InfoMessage
                      messageType={formStatus.color}
                      content={() => <div>{formStatus.message}</div>}
                    />
                  ) : (
                    ""
                  )}
                <FormGroup>
                  <div className="text-danger">{errors.first_name}</div>
                  <Label for="first_name">First name</Label>
                  <Input
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="profile"
                    placeholder="Your name here!"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phone_number">
                      Phone number
                      <p className="small text-muted mb-0">Should be in (512) 555-5555 format.</p>
                  </Label>
                  <div className="text-danger">{errors.phone_number}</div>
                  <Input
                    type="tel"
                    name="phone_number"
                    id="phone_number"
                    className="profile"
                    placeholder="(512) 555-5555"
                    // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <div className="text-danger">{errors.age}</div>
                  <Label for="age">Age</Label>
                  <Input
                    type="number"
                    name="age"
                    id="age"
                    className="profile"
                    placeholder=""
                    value={age}
                    onChange={e => setAge(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <div className="text-danger">{errors.gender}</div>
                  <Label for="gender">Gender <span className="text-muted">(for matching)</span></Label>
                  <br />
                  <select
                    name="gender"
                    id="gender"
                    className="profile"
                    // defaultValue={gender}
                    value={gender}
                    onChange={e => setGender(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      --
                    </option>
                    <option value="male">
                      Male
                    </option>
                    <option value="female">
                      Female
                    </option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <Label for="where_from">Where are you from?</Label>
                  <Input
                    type="text"
                    name="where_from"
                    id="where_from"
                    className="profile"
                    value={whereFrom}
                    placeholder="The Big Apple"
                    onChange={e => setWhereFrom(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="occupation">What do you do for a living?</Label>
                  <Input
                    type="text"
                    name="occupation"
                    id="occupation"
                    className="profile"
                    placeholder="Marketing"
                    value={occupation}
                    onChange={e => setOccupation(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="interests">
                    What are three things you like doing for fun?
                  </Label>
                  <Input
                    type="text"
                    name="interests"
                    id="interests"
                    className="profile"
                    placeholder="Sippin' champagne, building stuff, traveling"
                    value={interests}
                    onChange={e => setInterests(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                <div className="text-danger">{errors.profilePicture}</div>
                  <Label for="profile_picture">
                    Please upload your profile picture.
                  </Label>
                  { profilePicturePreview ? ( 
                  <Media
                  object
                  src={profilePicturePreview}
                  className="profile-picture-preview"
                /> ) : "" }
                <br />
                { formSubmitting && 
                ( <div className="progress mt-2">
                    <div
                      className="progress-bar progress-bar-info progress-bar-striped"
                      role="progressbar"
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: progress + "%" }}
                    >
                      {progress}%
                    </div>
                    </div>
                    )
                  }
                  <Input
                    type="file"
                    id="upload_profile_picture"
                    // name="profile_picture"
                    bsSize="sm"
                    accept="image/*"
                    onChange={event => handleProfilePictureChange(event)}
                  />
                  {imageUploadError ? (
                    <FormText color="danger">{imageUploadError}</FormText>
                  ) : (
                    ""
                  )}
                  <FormText color="muted">
                    Your face should be clearly visible. No nudity or NSFW
                    images, please.
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    className="mb-3"
                    disabled={profFormButtonDisabled}
                  >
                    Save Profile Changes
                  </Button>
                </FormGroup>
              </div>
            </Form>
          </Col>
          {/* </Row>*/}

          {/*<Row className="mb-5">*/}

          {/*</Row>*/}
        </Row> }
      </Container>
    )
  );
};

export default Profile;

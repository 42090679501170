import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const logout = history => {
    localStorage.removeItem("token");
    localStorage.removeItem("dateOrder");
    history.push('/login')
  };

const renderLink = activeEvent => {
    const go_to_check_in = activeEvent.status === "checking_in"
    let my_status = activeEvent.participating_status
    if (go_to_check_in) {
      return <Link to={`/events/${activeEvent.id}/check-in`}>Click here!</Link>
    }
    else if (!["checked_in", "participating"].includes(my_status) && activeEvent.status === "in_progress") {
      return <>The event has already started. Please see a host for help.</>
    }
    else if (["checked_in", "participating"].includes(my_status) && activeEvent.status === "in_progress") {
      return <Link to={`/events/${activeEvent.id}/dating`}>Click here!</Link>
    }
    else if (["checked_in", "participating", "completed"].includes(my_status) && ["show_matches", "complete"].includes(activeEvent.status)) {
      return (
        <>
          <h6>Matches are now available!</h6>
          <Link to={`/events/${activeEvent.id}/matches`}>Click here to view your matches!</Link>
        </>
      )
    }
  }

export { logout, renderLink }
import { Route, Redirect, } from "react-router-dom";
import React, { Component } from "react";

export function PrivateRoute ({ children, loggedIn, ...rest }) {
    return (
      <Route {...rest} render={() => {
        return loggedIn === true
          ? children
          : <Redirect to='/login' />
      }} />
    )
}
import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";


const BeginCheckIn = ({ event, handleBeginCheckIn, error }) => {

  const { eventId } = useParams();
  console.log(eventId)
  console.log(event)

    const [btnDisabled, setBtnDisabled] = useState(true)

  if (!event) return ( <>Loading...</> )
  return (
    <div className="container mt-3">
      <Row>
        <Col>
        {error()}
          <div className="card">
            <h4 className="card-header">
             Check into {event.event_name}
            </h4>
            <div className="card-body">
                {/* <div className="w-100 mb-3">
                    Please only check in once you've arrived at the venue.
                </div> */}
              <h5>How It Works</h5>
              <ol style={{ fontSize: ".9rem", lineHeight: 1.6 }}>
                <li style={{ marginTop: "4px" }}>
                  During each date, you'll see your date's profile so you have a jumping-off point for conversation.
                </li>
                {/* <li style={{ marginTop: "4px" }}>
                    The app will tell you when the date is starting and the countdown timer will start automatically so you'll know how much time you have left.
                </li> */}
                <li style={{ marginTop: "4px" }}>
                    After each date, you'll fill out the date scorecard to select whether—and in what categories—you liked your date.
                </li>
                <li style={{ marginTop: "4px" }}>
                    After the final date, you'll see who your matches were!
                </li>
              </ol>
              <hr />
            <div><button 
                size="lg"
                className="mx-auto btn btn-lg btn-primary"
                style={{textAlign: "center", width: "65%", display: "block", marginBottom: "10px"}}
                onClick={e => handleBeginCheckIn()}>
                Check me in!</button>
                </div>
                <div className="text-center">If you need any help, please don't hesitate to ask the event organizer, Robert Geller.</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BeginCheckIn;
